@import (reference) '~@styles/typography.module.less';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: @u-fill-2x;

  &.horizontal {
    flex-direction: row;
    flex-wrap: wrap;

    .inputWrapper {
      flex-basis: 80%;
    }

    .contextualErrors {
      margin-left: 20%;
    }
  }
}

.contextualErrors {
  .bodySmall();
  color: @redalert;
}

.errorList {
  margin: 0;
}

.horizontalLabel {
  flex-basis: 20%;
}

.unitInputs {
  display: flex;
  gap: @u-fill-2x;

  > input {
    flex-basis: calc(100% * 2 / 3);
  }

  > div {
    flex-grow: 1;
  }
}
