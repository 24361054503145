// HEADER STYLES

.display {
  color: @black-100;
  font-family: 'Inter';
  font-size: 50px;
  font-weight: 700;
  line-height: 56px;
}

.headline {
  color: @black-100;
  font-family: 'Inter';
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.headlineDeEmphasis {
  color: @black-100;
  font-family: 'Inter';
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

.title {
  color: @black-100;
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.titleDeEmphasis {
  color: @black-100;
  font-family: 'Inter';
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.subtitle {
  color: @black-100;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.subtitleDeEmphasis {
  color: @black-100;
  font-family: 'Inter';
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

// BODYTEXT STYLES
.bodyLarge {
  color: @black-100;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.bodyLargeEmphasis {
  color: @black-100;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.bodyMedium {
  color: @black-100;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.bodyMediumEmphasis {
  color: @black-100;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.bodySmall {
  color: @black-100;
  font-family: 'Inter';
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.bodyXsmall {
  color: @black-100;
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
}

// LABEL STYLES
.labelLarge {
  .bodyMedium();
  font-weight: 600;
  letter-spacing: 0px;
}

.labelMedium {
  .bodyMedium();
  color: @black-45;
  font-weight: 600;
  letter-spacing: 0px;
}

.labelSmall {
  .bodyMedium();
  font-weight: 600;
  letter-spacing: 1px;
}

.labelXsmall {
  .bodySmall();
  font-weight: 600;
  letter-spacing: 0px;
}
