@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  img,
  video {
    display: inline;
  }

  a {
    @apply text-text-link;
  }
}
