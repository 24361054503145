.skeletonShimmer {
  background: #eeeeee;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: skeleton-shimmer 1s infinite;
  }

  @keyframes skeleton-shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
